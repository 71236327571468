import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Ni à gauche, ni à droite" />
    <h3 className='underline-title'>Ni à gauche, ni à droite</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Ne suis-je pas convenable à tes yeux?<br />
        Ne suis-je pas fidèles à tes décrets?<br />
      </p>
      <p>
        Mon silence est nuisible à ta santé?<br />
        Et si je faisais du vacarme près de ta maison? <br />
        M'en féliciterais-tu? <br />
      </p>
      <p>
        Mon silence est agaçant<br />
        Mon vacarme est dérangeant <br />
        Que dois-je faire donc?<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
